import React, { useContext } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { AuthContext } from 'context/authContext';

import Heading from 'components/atoms/Heading';
import Logo from 'components/atoms/Logo';

import Button from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import LoginTemplate from '../../templates/LoginTemplate';
import Field from '../../molecules/Field';
import Form, { useForm } from '../../molecules/Form';

import { SubTitle, FormHolder, StyledForm, LogoWrap } from './Login.styles';
import logoImg from 'assets/images/logo-d.png';
// import Permissions from 'helpers/defaultPermission.json';
// import defaultRoles from 'helpers/defaultRoles';
// import Toast from 'components/molecules/Toast';
// import userService from 'services/userService';

function Login() {
  // const restorePermissions = async () => {
  //   try {
  //     const res = await userService.restoreRolesM({
  //       permissions: Permissions,
  //       roles: defaultRoles(),
  //     });
  //     if (res) {
  //       Toast({
  //         type: 'success',
  //         message: 'Permissions restored successfully',
  //       });
  //       // refetch();
  //     }
  //   } catch (ex) {
  //     Toast({
  //       type: 'error',
  //       message: ex?.message,
  //     });
  //   }
  // };

  const [form] = useForm();
  const { onLogin, user_loading } = useContext(AuthContext);
  return (
    <LoginTemplate>
      <FormHolder>
        {/* <Button
          type="outline"
          onClick={restorePermissions}
          iconMobile
          prefix={<i className="material-icons-outlined">restore</i>}
        >
          <span className="text">Restore</span>
        </Button> */}
        <LogoWrap>
          <a href="#">
            <img src={logoImg} alt="Logo" />
          </a>
        </LogoWrap>
        <Heading level={1}>Sign in</Heading>
        <SubTitle>
          Hello there! Sign in and start managing <br /> Webevis CRM.
        </SubTitle>
        <StyledForm form={form} onSubmit={onLogin}>
          <Form.Item
            type="text"
            label="Email / Username"
            name="email"
            placeholder="Your Email or Username"
            // prefix={<i className="material-icons-outlined">email</i>}
            rules={[
              { required: true },
              {
                pattern: /^.{0,256}$/,
                message: 'Maximum Character Length is 256',
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="password"
            label="Password"
            name="password"
            placeholder="Your Password"
            // prefix={<i className="material-icons-outlined">lock</i>}
            rules={[
              {
                required: true,
              },
              {
                pattern: /^.{8,64}$/,
                message: 'Minimum Character Length is 8 and Maximum Character Length is 64',
              },
            ]}>
            <Field />
          </Form.Item>
          <Button
            className="btn-login"
            loading={user_loading}
            type="primary"
            htmlType="submit"
            width={150}
            css="margin: 0 auto; background-color: #fff; color:#424954;">
            Sign in
          </Button>
        </StyledForm>
      </FormHolder>
    </LoginTemplate>
  );
}

export default Login;
