import React, { useContext, useEffect, useState, useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Select from 'components/atoms/Select';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import blogService from 'services/commonService';
import { Error } from '../../molecules/Field/Field.styles';
import ImgPreview from '../../molecules/ImgPreview';
import Flex from 'components/atoms/Flex/Index';
import { Editor } from '@tinymce/tinymce-react';

function BlogForm({ blog: _blg, onClose = () => {} }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { refetch } = useContext(AuthContext);
  const [bannerImg, setBannerImg] = useState(null);
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [imgError, setImgError] = useState(null);

  const { category_data } = blogService.GetCategories({}, refetch);
  const { authors_data } = blogService.GetAuthors({}, refetch);
  const { blog_data: blog, blog_loading } = blogService.GetBlogById(_blg?._id, refetch);

  const categoryOpts = category_data?.categories.map(category => ({
    label: category.categoryTitle,
    value: category._id,
  }));

  const authorsOpts = authors_data?.authors.map(author => ({
    label: author.name,
    value: author._id,
  }));

  const editorRef = useRef(null);
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(description);
    }
  }, [description]);

  useEffect(() => {
    if (blog && !blog_loading && categoryOpts.length) {
      form.setFieldsValue({
        title: blog.title,
        authorId: authorsOpts.find(({ value }) => blog?.authorId?._id.includes(value)),
        category: categoryOpts.find(({ value }) => blog?.category?._id.includes(value)),
        metaTitle: blog.metaTitle,
        metaDescription: blog.metaDescription,
      });
      setDescription(blog?.description);
      setBannerImg(blog?.bannerImg);
    } else {
      console.log('not working,,,,,,,,,', { blog });
    }
  }, [blog, blog_loading, category_data, authors_data]);

  // const handleEditorChange = content => {
  //   setDescription(content);
  // };

  const onSubmit = async data => {
    let desc = description;
    if (editorRef.current) {
      desc = editorRef.current.getContent();
    }

    if (!bannerImg) {
      setImgError('Banner image is required');
      return;
    }
    setError(null);
    setLoading(true);
    try {
      const postData = {
        title: data.title,
        authorId: data?.authorId?.value,
        description: desc,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        category: data?.category?.value,
        bannerImg,
      };
      if (blog) {
        await blogService.updateBlog(blog._id, postData);
      } else {
        await blogService.addBlog(postData);
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: 'Post saved successfully',
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  const handleImage = e => {
    setBannerImg(e.target.value);
    setImgError(null);
  };
  const handleImageSizeLimit = (e, items, editor) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        var imageSize = items[i].getAsFile().size;

        if (imageSize > 200 * 1024) {
          e.preventDefault();

          editor.notificationManager.open({
            text: 'Image size exceeds 200 KB limit',
            type: 'error',
          });

          return false;
        }
      }
    }

    return true;
  };
  return (
    <>
      <Form form={form} onSubmit={onSubmit}>
        <Grid
          xs={1}
          lg={2}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            sm
            type="text"
            label="Title"
            name="title"
            placeholder="Post Title"
            rules={[
              { required: true, message: 'Please enter post title' },
              {
                pattern: /^[A-Z]/,
                message: 'First character should be capital',
              },
              {
                pattern: /^.{5,200}$/,
                message: 'Character Length should be between 5 and 200',
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            sm
            options={authorsOpts}
            isSearchable
            name="authorId"
            label="Author"
            placeholder="Select Author"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            rules={[
              { required: true, message: 'Select atleast one author' },
              {
                transform: value => !value?.length,
                message: 'Select at least one author',
              },
            ]}>
            <Select />
          </Form.Item>
        </Grid>
        <Grid
          xs={1}
          lg={2}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            sm
            type="text"
            label="Metatitle"
            name="metaTitle"
            placeholder="Metatitle"
            rules={[
              { required: true, message: 'Please enter meta title' },
              {
                pattern: /^[A-Z]/,
                message: 'First character should be capital',
              },
              {
                pattern: /^.{5,}$/,
                message: 'Length should be atleast 5',
              },
            ]}>
            <Field />
          </Form.Item>

          <Form.Item
            sm
            type="text"
            label="Metadescription"
            name="metaDescription"
            placeholder="Metadescription"
            rules={[
              { required: true, message: 'Please enter post meta description' },
              {
                pattern: /^[A-Z]/,
                message: 'First character should be capital',
              },
              {
                pattern: /^.{5,}$/,
                message: 'Length should be atleast 5',
              },
            ]}>
            <Field />
          </Form.Item>
        </Grid>

        <Grid
          xs={1}
          lg={2}
          colGap={20}
          css={`
            align-items: center;
          `}>
          editor
          <Form.Item
            sm
            options={categoryOpts}
            isSearchable
            name="category"
            label="Category"
            placeholder="Select Category"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            rules={[
              { required: true, message: 'Select atleast one cateogry' },
              {
                transform: value => !value?.length,
                message: 'Select at least one cateogry',
              },
            ]}>
            <Select />
          </Form.Item>
        </Grid>

        <Grid
          xs={1}
          lg={1}
          css={`
            margin-top: 20px;
            align-items: center;
          `}>
          <Editor
            onInit={(evt, editor) => {
              editorRef.current = editor;
              // console.log({ editor: editor.initialized });
            }}
            initialValue={description}
            init={{
              image_resizing: true,
              height: 500,
              plugins: [
                'advlist',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'help',
                'wordcount',
              ],
              toolbar:
                'undo redo | casechange blocks | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help | link',

              link_rel_list: [
                // { title: 'No Referrer', value: 'noreferrer' },
                // { title: 'External Link', value: 'external' },
                {
                  title: 'Do Follow',
                  value: 'dofollow',
                },
                {
                  title: 'No Follow',
                  value: 'nofollow',
                },
              ],
              content_style: 'img { width:100%; height:auto; }',
              menubar: 'insert',
              a11y_advanced_options: true,
              setup: function (editor) {
                let savedSelection;
                editor.on('keyup', function (e) {
                  if (e.keyCode === 32 || e.keyCode === 13) {
                    editor.undoManager.add();
                    savedSelection = editor.selection.getBookmark(2, true);
                  }
                });
                editor.on('undo', function (e) {
                  if (savedSelection) {
                    editor.selection.moveToBookmark(savedSelection);
                  }
                });

                // // Handle the paste event to check image sizes
                // editor.on('paste drop', async function (e) {
                //   var items;
                //   if (e.type === 'paste') {
                //     var clipboardData = e.clipboardData || window.clipboardData;
                //     items = clipboardData.items;
                //   } else if (e.type === 'drop') {
                //     e.preventDefault();
                //     items = e.dataTransfer.items;
                //   }

                //   // Iterate through items and check if they are images
                //   for (var i = 0; i < items.length; i++) {
                //     if (items[i].type.indexOf('image') !== -1) {
                //       try {
                //         var compressedImage = await compressImage(items[i].getAsFile());
                //         const compressedSizeInBytes = Math.round((compressedImage.length * 3) / 4);
                //         if (compressedSizeInBytes > 200 * 1024) {
                //           e.preventDefault();

                //           editor.notificationManager.open({
                //             text: 'Image size exceeds 200 KB limit',
                //             type: 'error',
                //           });
                //           return false;
                //         } else {
                //           console.log(compressedImage);
                //           editor.insertContent('<img src="' + compressedImage + '" alt=" " />');
                //         }
                //       } catch (error) {
                //         console.error('Error compressing image:', error);
                //       }
                //     }
                //   }
                // });
                editor.on('paste', function (e) {
                  var clipboardData = e.clipboardData || window.clipboardData;
                  var items = clipboardData.items;
                  handleImageSizeLimit(e, items, editor);
                });

                editor.on('drop', function (e) {
                  var items = e.dataTransfer.items;

                  handleImageSizeLimit(e, items, editor);
                });
              },
            }}
          />

          <Error>{error}</Error>
        </Grid>

        <Flex
          direction="column"
          justify="flex-start"
          align="flex-start"
          wrap="no-wrap"
          gap={bannerImg ? '10px' : '0px'}>
          {bannerImg && (
            <Grid style={{ width: '100%', marginBottom: '20px' }} xs={1} lg={1}>
              <ImgPreview src={bannerImg} alt="bannerImage" />
            </Grid>
          )}
          <Field noMargin={true} label="Upload File" name="fileInput" type="chooseFile" onChange={handleImage} />
          <Error>{imgError}</Error>
        </Flex>
        <Button loading={loading} type="primary" htmlType="submit" style={{ marginTop: '20px' }}>
          Save
        </Button>
      </Form>
    </>
  );
}

export default BlogForm;
