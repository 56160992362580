import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import blogService from 'services/commonService';

function CategoryForm({ category, onClose = () => {} }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { refetch } = useContext(AuthContext);

  useEffect(() => {
    if (category) {
      form.setFieldsValue({
        title: category?.categoryTitle,
      });
    }
  }, [category]);

  const onSubmit = async data => {
    try {
      setLoading(true);
      if (category) {
        await blogService.updateCategory(category._id, {
          categoryTitle: data?.title,
        });
      } else {
        await blogService.addCategory({
          categoryTitle: data?.title,
        });
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: 'Category saved successfully',
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Grid
        xs={1}
        lg={1}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label="Title"
          name="title"
          placeholder="Category Title"
          rules={[
            { required: true, message: 'Please enter category title' },
            {
              pattern: /^[A-Z]/,
              message: 'First character should be capital',
            },
          ]}>
          <Field />
        </Form.Item>
      </Grid>
      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default CategoryForm;
