const defaultPermissions = require('./defaultPermission.json');

export default function defaultRoles() {
  const ROLES = [
    {
      name: 'DEVELOPER',
      permissions: ['dashboard.nav', 'user.nav', 'user.edit', 'user.update-password'],
    },
  ];

  return [
    {
      type: 'SUPER_USER',
      permissions: defaultPermissions,
    },
    {
      type: 'DEVELOPER_SUPER_USER',
      permissions: defaultPermissions,
    },
    ...ROLES.map(e => ({
      type: e.name,
      permissions: defaultPermissions.map(z => (e.permissions.includes(z.can) ? z : null)).filter(x => x !== null),
    })),
  ];
}
