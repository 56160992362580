import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import Select from 'components/atoms/Select';
import PhoneInput from 'components/atoms/PhoneInput';
import queryService from 'services/queryService';

function EnquiryForm({ enquiry, onClose = () => {} }) {
  const [form] = useForm();
  const [state, setState] = useState({ valid: false });
  const [loading, setLoading] = useState(false);
  const { refetch } = useContext(AuthContext);
  const serviceOptions = [
    { value: 'SEO', label: 'SEO' },
    { value: 'SEM', label: 'SEM' },
    { value: 'SMM', label: 'SMM' },
    { value: 'SMO', label: 'SMO' },
    { value: 'Web App Dev', label: 'Web App Dev' },
    { value: 'UI/UX Designing', label: 'UI/UX Designing' },
    { value: 'UI/UX Development', label: 'UI/UX Development' },
    { value: 'Devops', label: 'Devops' },
    { value: 'Graphic Desigining', label: 'Graphic Desigining' },
    { value: 'Ecommerce', label: 'Ecommerce' },
    { value: 'Wordpress Development', label: 'Wordpress Development' },
    { value: 'Android Development', label: 'Android Development' },
    { value: 'IOS Development', label: 'IOS Development' },
    { value: 'React Native Dev', label: 'React Native' },
    { value: 'Flutter Development', label: 'Flutter Development' },
    { value: 'Email Marketing', label: 'Email Marketing' },
    { value: 'Content Marketing', label: 'Content Marketing' },
    { value: 'Youtube Marketing', label: 'Youtube Marketing' },
    { value: 'AI Website Audit', label: 'AI Website Audit' },
    { value: 'AI Compititor Analysis', label: 'AI Compititor Analysis' },
    { value: 'Email Chat Support', label: 'Email Chat Support' },
    { value: 'Inbound Calls', label: 'Inbound Calls' },
    { value: 'Outbound Calls', label: 'Outbound Calls' },
    { value: 'Cold Calling', label: 'Cold Calling' },
  ];
  const resourceOptions = [
    { value: 'NodeJs Developer', label: 'NodeJs Developer' },
    { value: '.Net Developer', label: '.Net Developer' },
    { value: 'React Js Developer', label: 'React Js Developer' },
    { value: 'Seo Expert', label: 'Seo Expert' },
    { value: 'Socia Media Expert', label: 'Socia Media Expert' },
    { value: 'PPC Expert', label: 'PPC Expert' },
    { value: 'Content Writer', label: 'Content Writer' },
    { value: 'Email Expert', label: 'Email Expert' },
    { value: 'Full Stack Developer', label: 'Full Stack Developer' },
    { value: 'Laravel Developer', label: 'Laravel Developer' },
    { value: 'Python Developer', label: 'Python Developer' },
    { value: 'UI/UX Developer', label: 'UI/UX Developer' },
    { value: 'UI/UX Designer', label: 'UI/UX Designer' },
    { value: 'Graphic Designer', label: 'Graphic Designer' },
    { value: 'Angular Developer', label: 'Angular Developer' },
    { value: 'React Native Developer', label: 'React Native Developer' },
    { value: 'Java Developer', label: 'Java Developer' },
    { value: 'Wordpress Developer', label: 'Wordpress Developer' },
    { value: 'Android Developer', label: 'Android Developer' },
    { value: 'Swift Developer', label: 'Swift Developer' },
    { value: 'SQA Engineer', label: 'SQA Engineer' },
    { value: 'Penetration Tester', label: 'Penetration Tester' },
    { value: 'Project Cordinator', label: 'Project Cordinator' },
    { value: 'Project Manager', label: 'Project Manager' },
    {
      value: 'Automation Engineer',
      label: 'Automation Engineer',
    },
  ];
  useEffect(() => {
    if (enquiry) {
      form.setFieldsValue({
        ...enquiry,
        resources: enquiry?.resources?.map(item => ({ label: item, value: item })),
        services: enquiry?.services?.map(item => ({ label: item, value: item })),
      });
      setState({
        ...enquiry,
        resources: enquiry?.resources?.map(item => ({ label: item, value: item })),
        services: enquiry?.services?.map(item => ({ label: item, value: item })),
      });
    }
  }, [enquiry]);
  const onSubmit = async data => {
    const updatedPayload = { ...data, ...state };
    const { valid, ...rest } = updatedPayload;
    try {
      setLoading(true);
      let response;
      if (enquiry) {
        response = await queryService.updateEnquiry(enquiry._id, rest);
      } else {
        response = await queryService.createEnquiry({ inquiryType: 'Resource', region: 'Default', ...rest });
      }

      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: response.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const handleOnChange = value => {
    setState(_ => ({ ..._, ...value }));
  };
  return (
    <Form className="from-wrap" form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label="Name"
          name="name"
          placeholder="Equirer's name"
          rules={[
            {
              pattern: /^[a-zA-Z_ ]+$/,
              message: 'Only alphabets and _ are allowed',
            },
          ]}>
          <Field />
        </Form.Item>

        <Form.Item
          sm
          type="email"
          label="Email"
          name="email"
          placeholder="Email"
          rules={[
            { required: true, message: 'Please enter email address' },
            { email: true, message: 'Please enter a valid email' },
            { max: 40, message: 'Email should be at max 40 characters!' },
          ]}>
          <Field />
        </Form.Item>

        <PhoneInput
          value={state?.phone_number}
          required
          name="phone_number"
          label="Phone Number"
          onChange={handleOnChange}
        />

        <Form.Item
          sm
          type="text"
          label="Company Name"
          name="company"
          placeholder="Company Name"
          rules={[
            {
              pattern: /^[a-zA-Z_ ]+$/,
              message: 'Only alphabets and _ are allowed',
            },
          ]}>
          <Field />
        </Form.Item>

        <Form.Item
          sm
          options={resourceOptions}
          isSearchable
          isMulti
          name="resources"
          label="Resources"
          placeholder="Select Resources"
          hideSelectedOptions={false}
          closeMenuOnSelect={false}>
          <Select />
        </Form.Item>

        <Form.Item
          sm
          options={serviceOptions}
          isSearchable
          isMulti
          name="services"
          label="Services"
          placeholder="Select Services"
          hideSelectedOptions={false}
          closeMenuOnSelect={false}>
          <Select />
        </Form.Item>
        <div />
      </Grid>
      <Form.Item
        type="text"
        placeholder="Website Url"
        label="Company Website"
        name="company_website"
        rules={[
          {
            pattern: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&=_.-]+)*$/,
            message: 'Please enter a valid url',
          },
        ]}>
        <Field />
      </Form.Item>

      <Form.Item
        type="text"
        placeholder="Source"
        label="Source"
        name="source"
        // rules={[
        //   {
        //     pattern: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&=_.-]+)*$/,
        //     message: 'Please enter a valid url',
        //   },
        // ]}
      >
        <Field />
      </Form.Item>

      {/* Multipe Fields Component */}

      {/* <Form.Item
        type="text"
        placeholder="Source"
        label="Data"
        name="source"
      >
        <MultipleField />
      </Form.Item> */}

      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}></Grid>
      <Form.Item type="textarea" label="Important Details" name="info" placeholder="Share Important Details">
        <Field />
      </Form.Item>
      <Button
        disabled={!state?.valid}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default EnquiryForm;
