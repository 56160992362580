import React, { useContext, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import Styled from 'styled-components/macro';
import { useMediaPredicate } from 'react-media-hook';
import { SideNavContext } from 'context/sideNavContext';
import { LoadingContext } from 'context/loadingContext';
import LogoP from 'assets/images/favicon.png';
import LogoS from 'assets/images/logo-sm.svg';
import rightArrows from 'assets/images/rightArrows.png';
import leftArrows from 'assets/images/leftArrows.png';

import LogoFull from 'assets/images/NewLogo.webp';
import { AuthContext } from 'context/authContext';
import sideNavData from 'nav.json';
import UserActions from 'components/organisms/UserActions';

import { SideNavbar, Nav, Ul, CloseButton, LogoHolder, Logo, SearchField, StyledField, Button } from './SideNav.styles';
import SubMenu from './SubMenu';

function Navbar() {
  const { toggleSideNav, sideNavState } = useContext(SideNavContext);
  const [searchText, setSearchText] = useState('');
  const { allowedPages, user, active, setActive } = useContext(AuthContext);
  const { isLoading } = useContext(LoadingContext);
  // const [active, setActive] = useState(false);

  const MaxWidth991 = useMediaPredicate('(max-width: 991px)');
  useEffect(() => {
    !sideNavState && document.body.classList.remove('nav-active');
  }, [sideNavState]);
  const sideBarItems = useMemo(
    () =>
      sideNavData
        .filter(({ file }) =>
          !searchText.trim()
            ? allowedPages.includes(file)
            : allowedPages.includes(file) && file.includes(searchText.trim()),
        )
        .map((item, index) => <SubMenu item={item} key={index} />),
    [searchText, isLoading, sideNavData, allowedPages],
  );

  const toggleVisibility = () => {
    setActive(!active);
  };
  return (
    <>
      <SideNavbar css={isLoading && 'background:var(--dark);'} $loading={active} width={active} $hamburger={active}>
        <LogoHolder>
          <Logo to="/" $hamburger={active}>
            <img src={LogoS} alt="WebEvis" className="logo-small" />
            <img src={LogoFull} alt="WebEvis" className="logo-full" />
          </Logo>
          {!MaxWidth991 && (
            <Button onClick={toggleVisibility}>
              {active ? <img src={leftArrows} alt="leftArrows" /> : <img src={rightArrows} alt="rightArrows" />}
            </Button>
          )}
        </LogoHolder>
        {MaxWidth991 && (
          <CloseButton onClick={toggleSideNav}>
            <i className="material-icons-outlined">close</i>
          </CloseButton>
        )}
        <SearchField>
          <StyledField
            type="search"
            placeholder="Search..."
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            prefix={<i className="material-icons-outlined">search</i>}
          />
        </SearchField>
        <Nav id="sub-menu">
          <Ul>{sideBarItems}</Ul>
        </Nav>
      </SideNavbar>
    </>
  );
}
export default Navbar;
