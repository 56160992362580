import React, { useState, useContext } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import { LoadingContext } from 'context/loadingContext';
import { SideNavContext } from 'context/sideNavContext';
import { AuthContext } from 'context/authContext';
import DummyPic from 'assets/images/ProfilePic.png';

import {
  ProfileHolder,
  UserWrap,
  ImgBox,
  TextBox,
  DropDown,
  Ul,
  Li,
  StyledLink,
  Name,
  Text,
  IconHolder,
} from './UserActions.styles';

function UserActions({ meeting }) {
  const { toggleSideNav } = useContext(SideNavContext);
  const { onLogout, user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading } = useContext(LoadingContext);
  return (
    <>
      {isLoading ? (
        <Skeleton circle height={40} width={40} />
      ) : (
        <ProfileHolder dropdownopen={isOpen ? isOpen : undefined}>
          <UserWrap onClick={() => setIsOpen(!isOpen)}>
            <ImgBox>
              <img
                src={user?.userPic && user?.userPic?.length ? user?.userPic : DummyPic}
                width="40"
                height="40"
                alt="userPic"
              />
            </ImgBox>
            <TextBox dropdownopen={isOpen ? isOpen : undefined}>
              <Name>{user?.username}</Name>
            </TextBox>
            <i className="icon-chevron-down material-icons-outlined">expand_more</i>
          </UserWrap>

          <DropDown dropdownopen={isOpen ? isOpen : undefined}>
            <Ul>
              <Li index="1">
                {meeting && (
                  <StyledLink
                    css="cursor: pointer;"
                    to={`/meeting`}
                    onClick={() => {
                      toggleSideNav();
                    }}>
                    <IconHolder>
                      <i className="material-icons-outlined">groups</i>
                    </IconHolder>
                    <Text>Meeting Times</Text>
                    {/* <i className="material-icons-outlined">chevron_right</i> */}
                  </StyledLink>
                )}
              </Li>
              <Li index="2">
                <StyledLink
                  as="span"
                  css="cursor: pointer;"
                  onClick={e => {
                    e.preventDefault();
                    toggleSideNav();
                    onLogout();
                  }}>
                  <IconHolder>
                    <i className="material-icons-outlined">logout</i>
                  </IconHolder>
                  <Text>Logout</Text>
                </StyledLink>
              </Li>
            </Ul>
          </DropDown>
        </ProfileHolder>
      )}
    </>
  );
}

export default UserActions;
