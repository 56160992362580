import styled from 'styled-components/macro';
import Link from 'components/atoms/Link';
import Form from 'components/molecules/Form';

export const SubTitle = styled.span`
  color: var(--light-gray-theme);
  font-weight: 500;
  margin-bottom: 20px;
  display: block;
  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const LoginHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 30px;
  @media (min-width: 768px) {
    padding: 40px 80px;
  }
`;

export const FormHolder = styled.div`
  width: 100%;
  .btn-login {
    &:hover {
      color: #fff;
    }
  }
`;

export const StyledForm = styled(Form)`
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
  input {
    color: #424954;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.16);
  }
  label {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #424954;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--light-gray);
  &:hover {
    i {
      transform: translateX(-5px);
    }
  }
  i {
    color: var(--primary);
    transition: transform 0.3s linear;
  }
`;

export const LogoWrap = styled.div`
  margin: 0 0 50px auto;
  text-align: left;
  img {
    max-width: 100%;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 90px;
  }
  @media screen and (max-width: 1024px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;
