import React, { useContext } from 'react';

// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

import { LoadingContext } from 'context/loadingContext';
import Header from 'components/molecules/Header';
import ModalContainer from 'components/molecules/ModalContainer';

import Button from 'components/atoms/Button';
import topNavData from 'nav.json';
import { AuthContext } from 'context/authContext';

import PermissionsForm from 'components/organisms/PermissionsForm';
import RolesForm from 'components/organisms/RolesForm';

import { useParams } from 'react-router-dom';

import Permissions from 'helpers/defaultPermission.json';
import defaultRoles from 'helpers/defaultRoles';
import Toast from 'components/molecules/Toast';
import userService from 'services/userService';
import UserForm from 'components/organisms/UserForm';
import EnquiryForm from 'components/organisms/EnquiryForm';
import BlogForm from 'components/organisms/BlogForm';
// import TagForm from "components/organisms/TagForm";
import CategoryForm from 'components/organisms/CategoryForm';
import ContactForm from 'components/organisms/ContactForm';
import AuthorForm from 'components/organisms/AuthorForm';
import CareerForm from 'components/organisms/CareerForm';

function TopBar() {
  const { isLoading } = useContext(LoadingContext);

  const { refetch, hasPermission } = useContext(AuthContext);

  // eslint-disable-next-line prefer-const
  let { view: title, child } = useParams();

  // eslint-disable-next-line prefer-const
  let { buttons = [], subNav = [] } = topNavData.find(({ file }) => file === title);

  if (subNav?.length) {
    const { buttons: subNavButtons } = subNav.find(({ file }) => file === child) ?? { buttons: [] };
    if (child) {
      buttons = [...subNavButtons];
    } else {
      buttons = [...buttons, ...subNavButtons];
    }
  }

  const restorePermissions = async () => {
    try {
      const res = await userService.restoreRoles({
        permissions: Permissions,
        roles: defaultRoles(),
      });
      if (res) {
        Toast({
          type: 'success',
          message: 'Permissions restored successfully',
        });
        refetch();
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };

  return (
    <>
      <Header
        title={
          child
            ? child.split('-').join(' ')
            : title
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
        }>
        {isLoading ? (
          <Skeleton rectangle height={40} width={131} css="border-radius:8px !important;" />
        ) : (
          <>
            {buttons.includes('create-permission') && hasPermission('permission.create') && (
              <ModalContainer
                lg
                title="Create Permission"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Permission</span>
                  </Button>
                )}
                content={({ onClose }) => <PermissionsForm onClose={onClose} />}
              />
            )}
            {buttons.includes('restore-role') && hasPermission('role.restore') && (
              <Button
                className="btn-restore"
                type="outline"
                onClick={restorePermissions}
                iconMobile
                prefix={<i className="material-icons-outlined">restore</i>}>
                <span className="text">Restore</span>
              </Button>
            )}
            {buttons.includes('create-user') && hasPermission('user.create') && (
              <ModalContainer
                lg
                title="Create User"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create User</span>
                  </Button>
                )}
                content={({ onClose }) => <UserForm onClose={onClose} />}
              />
            )}
            {buttons.includes('create-role') && hasPermission('role.create') && (
              <ModalContainer
                lg
                title="Create Role"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Role</span>
                  </Button>
                )}
                content={({ onClose }) => <RolesForm onClose={onClose} />}
              />
            )}
            {buttons.includes('create-enquiry') && hasPermission('enquiry.create') && (
              <ModalContainer
                lg
                title="Create Enquiry"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Enquiry</span>
                  </Button>
                )}
                content={({ onClose }) => <EnquiryForm onClose={onClose} />}
              />
            )}
            {buttons.includes('create-post') && hasPermission('post.create') && (
              <ModalContainer
                lg
                title="Create Post"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Post</span>
                  </Button>
                )}
                content={({ onClose }) => <BlogForm onClose={onClose} />}
              />
            )}
            {/* {buttons.includes("create-tag") && hasPermission("post.create") && (
              <ModalContainer
                lg
                title="Create Tag"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}
                  >
                    <span className="text">Create Tag</span>
                  </Button>
                )}
                content={({ onClose }) => <TagForm onClose={onClose} />}
              />
            )} */}
            {buttons.includes('create-category') && hasPermission('post.create') && (
              <ModalContainer
                lg
                title="Create Category"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Category</span>
                  </Button>
                )}
                content={({ onClose }) => <CategoryForm onClose={onClose} />}
              />
            )}
            {buttons.includes('create-author') && hasPermission('post.create') && (
              <ModalContainer
                lg
                title="Create Author"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Author</span>
                  </Button>
                )}
                content={({ onClose }) => <AuthorForm onClose={onClose} />}
              />
            )}
            {buttons.includes('create-contact') && hasPermission('contact.create') && (
              <ModalContainer
                lg
                title="Create Contact"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Contact</span>
                  </Button>
                )}
                content={({ onClose }) => <ContactForm onClose={onClose} />}
              />
            )}
            {buttons.includes('create-position') && hasPermission('position.create') && (
              <ModalContainer
                lg
                title="Create Position"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Position</span>
                  </Button>
                )}
                content={({ onClose }) => <CareerForm onClose={onClose} />}
              />
            )}
          </>
        )}
      </Header>
    </>
  );
}

export default TopBar;
