import styled from 'styled-components/macro';
import BG from 'assets/images/bg.jpg';
import Banner from 'assets/images/banner-bg.jpg';

export const TemplateHolder = styled.div`
  min-height: 100vh;
  height: 100%;
  position: relative;
  z-index: 1;
  background-image: url(${Banner});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    /* background: var(--primary-gradient); */
    opacity: 0.78;
    background: var(--Linear, linear-gradient(98deg, #1fabd3 0.78%, #1ccc97 77.81%));
    z-index: -1;
    /* opacity: 0.6; */
  }
  h1 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 8px;
  }
`;

export const Content = styled.div`
  padding: 1rem;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
  @media (min-width: 992px) {
    padding: 3.75rem 4rem;
  }
`;

export const ContentHolder = styled.div`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  /* align-items: center; */
  padding: 30px 30px;
  @media screen and (min-width: 992px) {
    padding: 45px 45px 130px;
  }
  @media screen and (min-width: 768px) {
    padding: 45px 45px;
  }
`;
