import React, { Suspense } from 'react';

import Loaders from 'components/atoms/Loaders';
import { Content, UserActionsContainer } from './PageTemplate.styles';
import SideNav from '../../organisms/SideNav';
import TopBar from '../../../common/TopBar';
import UserActions from '../../organisms/UserActions/index';
import { useContext } from 'react';
import { SideNavContext } from 'context/sideNavContext';
import { AuthContext } from 'context/authContext';

function PageTemplate({ title, showTemplate, children, topBar }) {
  const { toggleSideNav, sideNavState } = useContext(SideNavContext);
  const { allowedPages, user, active, setActive } = useContext(AuthContext);

  return (
    <>
      {showTemplate ? (
        <Content isActive={active}>
          <div className="user-actions-container">
            <UserActions toggleSideNav={toggleSideNav} meeting={user?.canDoMeeting} />{' '}
          </div>
          <SideNav />
          <div className="upper" margin={active}>
            {topBar && <TopBar />}
          </div>

          <Suspense fallback={<Loaders />}>
            <div className="upper" margin={active}>
              {children}
            </div>
          </Suspense>
        </Content>
      ) : (
        <div className="lower">{children}</div>
      )}
    </>
  );
}

export default PageTemplate;
