import Compress from 'react-image-file-resizer';

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = this.getDateObject(new Date());
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
  document.cookie = `${name}=${value || ''}; path=/;`;

  return true;
};

export const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const clearCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `${name}=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  return true;
};

export const convertPdfBase64 = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

export const compressImage = (file, type = 'PNG ') => {
  let newHeight = Infinity;
  let newWidth = Infinity;
  let quality = 70;
  let maxSizeInKb = 100;

  if (file.size / 1024 > maxSizeInKb) {
    newHeight = 1000;
    newWidth = 1000;
    quality = 60;
  }

  return new Promise(resolve => {
    Compress.imageFileResizer(
      file,
      newWidth,
      newHeight,
      type,
      quality,
      0,
      uri => {
        console.log(uri.length);
        resolve(uri);
      },
      'base64',
    );
  });
};

export const convertToBase64 = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

export const timePending = ({ startTime, endTime, date, status, dashboard, meetTime }) => {
  //should return something like "Meeting will start in 5 minutes" or "Meeting has started" or meeting has ended" etc
  const now = new Date();
  const start = new Date(date);
  const end = new Date(date);
  start.setHours(startTime.split(':')[0]);
  start.setMinutes(startTime.split(':')[1]);
  end.setHours(endTime.split(':')[0]);
  end.setMinutes(endTime.split(':')[1]);
  let timeDiff;

  if (meetTime) {
    timeDiff = start.getTime() - now.getTime();
  } else {
    timeDiff = end.getTime() - now.getTime();
  }

  const days = Math.max(0, Math.floor(timeDiff / (1000 * 60 * 60 * 24)));
  const remHours = Math.floor(timeDiff / (1000 * 60 * 60));
  const remMinutes = Math.floor(timeDiff / (1000 * 60)) % 60;

  if (dashboard) {
    if (status === 'Cancelled') {
      return <span>N/A</span>; // Show 0 days if the meeting is cancelled
    }

    if (days > 0) {
      return <span>{`${days}  ${days > 1 ? 'days' : 'day'}`}</span>;
    } else {
      return (
        <span>
          {remHours > 0 ? `${remHours} hours and ` : ''}
          {remMinutes} minutes
        </span>
      );
    }
  } else {
    if (now < start && status !== 'Cancelled') {
      return (
        <small style={{ color: 'green' }}>
          The meeting will start in {remHours > 0 ? `${remHours} hours and` : ''} {remMinutes} minutes.
        </small>
      );
    }
    if (now > end || status === 'Cancelled') {
      return <small style={{ color: 'red' }}>Meeting has ended</small>;
    }
    return <small style={{ color: 'green' }}>Meeting has started</small>;
  }
};

export const formatTimeTo12Hour = time => {
  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours, 10);
  const minute = parseInt(minutes, 10);

  if (hour === 0) {
    return `12:${minute.toString().padStart(2, '0')} AM`;
  } else if (hour < 12) {
    return `${hour}:${minute.toString().padStart(2, '0')} AM`;
  } else if (hour === 12) {
    return `12:${minute.toString().padStart(2, '0')} PM`;
  } else {
    return `${hour - 12}:${minute.toString().padStart(2, '0')} PM`;
  }
};
